<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>联系人管理</span></div>
					</div>
					<div class="module-btns">
						<el-form inline size="small">
							<el-form-item>
								<el-select v-model="listParams.linkmanGroup" @change="search">
									<el-option label="全部" value=""></el-option>
									<el-option v-for="item in linkmanGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-input placeholder="搜索" v-model="listParams.linkmanName">
									<i slot="suffix" class="el-icon-search" @click="search"></i>
								</el-input>
							</el-form-item>
						</el-form>
						<div>
							<el-dropdown size="small" split-button type="primary" @command="handleCommand">
								添加联系人
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="import">从Excel文件导入</el-dropdown-item>
									<el-dropdown-item command="add">手动添加</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<el-button size="small" type="primary" @click="exportFile">导出</el-button>
							<el-button size="small" type="primary" @click="manage">管理组</el-button>
							<!-- <el-dropdown size="small" split-button type="primary" @command="handleCommand">
								联系记录
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="sentMail">已发邮件</el-dropdown-item>
									<el-dropdown-item command="sentMessage">已发短信</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown> -->
						</div>
					</div>
					<template v-if="list.length > 0">
						<el-table :data="list" class="form-table" border style="width: 100%" @selection-change="selectionChange">
							<el-table-column type="selection" width="40"></el-table-column>
							<el-table-column prop="name" label="名称"></el-table-column>
							<el-table-column prop="email" label="邮箱" ></el-table-column>
							<el-table-column prop="phone" label="手机"></el-table-column>
							<el-table-column prop="groupName" label="群组" ></el-table-column>
							<el-table-column prop="company" label="公司" ></el-table-column>
							<el-table-column fixed="right" label="操作" width="250">
								<template slot-scope="scope">
									<el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
									<el-button type="text" @click="set(scope.row)">设置组</el-button>
									<el-button type="text" @click="del(scope.row.id)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
					</template>
					<noData v-else text="暂时还没有数据哦！"></noData>
				</div>
				<el-dialog title="设置联系人组" :visible.sync="dialogVisible" width="460px">
					<el-form :model="form" ref="form" label-width="80px">
						<el-form-item label="联系人组" prop="groupIds" :rules="[{required:true,message:'请选择联系人组！',trigger:'blur'}]">
							<el-checkbox-group v-model="form.groupIds">
								<el-checkbox v-for="item in linkmanGroupList" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="save('form')">保存</el-button>
						<el-button @click="cancel">取消</el-button>
					</div>
				</el-dialog>
				<!-- 导入 -->
				<el-dialog title="导入联系人" :visible.sync="dialogFlagUpload" width='460px'>
					<el-form :model="formUpload" ref="formUpload" label-width="80px">
						<el-form-item prop="file" :file="[{ required: true, message: '请上传文件！', trigger: 'blur' }]">
							<el-upload ref="upload" class="upload-demo" action="/formadmin/linkman/linkmna_import_save.jhtml" :limit="1" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload" :on-success="uploadSuccess">
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">选择文件（2MB），.xls .xlsx</div>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<a href="/formadmin/linkman/linkman_download.jhtml" target="_blank" class="download-btn">模板导出</a>
						</el-form-item>
					</el-form>
					<!-- <div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="saveUpload('formUpload')">保存</el-button>
						<el-button @click="cancelUpload()">取消</el-button>
					</div> -->
				</el-dialog>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			linkmanGroupList:[],
			list:[],
			listParams:{
				linkmanGroup:'',
				linkmanName:'',
				pageNo:1,
				pageSize:20
			},
			count: 0,
			selecter:'',
			dialogVisible:false,
			form:{
				groupIds:[]
			},
			dialogFlagUpload:false,
			formUpload: {
				file:''
			},
			fileList:[]
		}
	},
	created(){
		
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getList();
	},
	methods: {
		search(){
			this.getList();
		},
		// 获取模板列表
		getList(){
			const _this = this;
			get('/formadmin/linkman/linkman_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
					_this.linkmanGroupList = res.data.linkmanGroupList;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getList();
		},
		manage(){
			const _this = this;
			_this.$router.push('/linkmanGroup')
		},
		// 表格选择联系人
		selectionChange(val){
			this.selecter = val;
		},
		// 编辑
		edit(id){
			const _this = this;
			_this.$router.push('/linkmanEdit?linkmanId='+id)
		},
		// 导出
		exportFile(){
			const _this = this;
			let linkmanIds = [];
			_this.selecter.forEach(item=>{
				linkmanIds.push(item.id)
			})
			window.location.href = '/formadmin/linkman/linkman_export.jhtml?linkmanIds='+linkmanIds.toString()
			// get('/formadmin/linkman/linkman_export.jhtml',{linkmanIds:linkmanIds.toString()}).then(res => {
			// 	if(res.code == 200){
			// 		_this.getList();
			// 	}else{
			// 		_this.$message.error(res.message);
			// 	}
			// }).catch(err => {
			// 	_this.$message.error(err);
			// })
		},
		// 导入
		importFile(){
			const _this = this;
			_this.formUpload = {
				file:''
			}
			_this.dialogFlagUpload = true;
			_this.$nextTick(()=>{
				_this.$refs['formUpload'].clearValidate();
			})
		},
		// // 保存
		// saveUpload(){
		// 	const _this = this;
		// 	_this.dialogFlagUpload = false;
		// 	_this.$refs['upload'].clearFiles();
		// 	_this.getList();
			
		// },
		// // 关闭弹窗
		// cancelUpload(){
		// 	const _this = this;
		// 	_this.dialogFlagUpload = false;
		// 	_this.$refs['upload'].clearFiles();
		// },
		uploadSuccess(res, file, fileList){	
			const _this = this;
			if(res.code == 200){
				_this.dialogFlagUpload = false;
				_this.$refs['upload'].clearFiles();
				_this.getList();
			}else{
				this.$message.error(res.message);
			}
			console.log(res,file,fileList,this.fileList)
		},
		beforeUpload(file){
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
			this.$message.error('文件大小不能超过 2MB!');
			}
			return isLt2M;
		},
		handleRemove(file, fileList){
			console.log(file,fileList)
		},
		handleCommand(command){
			if(command == 'add'){
				this.$router.push('/linkmanEdit')
			}else if(command == 'import'){
				this.importFile()
			}else if(command == 'sentMail'){
				this.$router.push('/linkmanEdit')
			}else if(command == 'sentMessage'){
				this.$router.push('/linkmanEdit')
			}
		},
		set(item){
			const _this = this;
			_this.dialogVisible = true;
			let list = item.groupName && item.groupName.split(",")
			let groupIds = []
			if(list){
				list.forEach(e => {
					_this.linkmanGroupList.forEach(group=>{
						if(group.name == e){
							groupIds.push(group.id)
						}
					})
				});
			}
			
			_this.form = {
				linkmanId : item.id,
				groupIds:groupIds
			}
			_this.$nextTick(()=>{
				_this.$refs['form'].clearValidate();
			})
		},
		// 取消
		cancel(){
			const _this = this;
			_this.dialogVisible = false;
		},
		// 保存
		save(form){
			const _this = this;
			let param = {
				linkmanId:_this.form.linkmanId,
				groupIds:_this.form.groupIds.toString()
			}
			_this.$refs[form].validate(valid=>{
				if(valid){
					post('/formadmin/linkman/set_group.jhtml',param).then(res => {
						if(res.code == 200){
							_this.dialogVisible = false;
							_this.getList();
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		
		// 删除
		del(id){
			const _this = this;
			this.$confirm('确定删除吗？删除后不可恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/linkman/linkman_delete.jhtml',{linkmanId:id}).then(res => {
					if(res.code == 200){
						_this.getList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
		.module-btns{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 10px;
			.el-form--inline .el-form-item{
				margin-bottom: 0;
			}
			.el-button{
				margin:0 10px;
			}
			.el-button+.el-button{
				margin-left: 0;
			}
			.el-icon-search{
				cursor: pointer;
			}
		}
	}
	.form-table{
		.img{
			width:200px;
			height: 100px;
		}
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-delete{background-position: -87px 2px;}
			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			overflow-y: auto;
			.el-form-item{
				&:last-child{
					margin-bottom: 0;
				}
				.avatar-uploader{
					width:100px;
					height: 100px;
					border:1px solid #DCDFE6;
						text-align: center;
					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width:100%;
						height: 100%;
						line-height:100px;
					}
					.avatar {
						width: 100%;
						height: 100%;
						display: block;
					}
				}
				.download-btn{
					color:#f80;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>